
html, body, #root, .App {
    height: 100%;
}

.k-chat {
    margin: unset;
    max-width: unset;

    /*height: 100%;*/
    /*min-height: 0; !* new *!*/
    /*flex: 1;*/
    height: calc(100vh - 135px);
}

.k-chat .k-message-list-content {
    height: 100% !important;
    overflow-y: scroll !important;
}

/*.k-chat-bubble {*/
/*    padding: 0;*/
/*}*/

/*.k-chat-bubble > div {*/
/*    line-height: 0;*/
/*    margin: auto*/
/*}*/

/*.k-chat-bubble > div {*/
/*    margin: auto;*/
/*}*/

.k-chat-bubble ul, .k-chat-bubble ol, .k-chat-bubble p {
    /*margin-top: 5px;*/
    /*margin-bottom: 0;*/
    /*padding: 15px;*/
    margin: auto
}

.k-chat .k-message-group {
    max-width: 95%;
}